import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Home.css';

function Home() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [text, setText] = useState("");
  const [key, setKey] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get('https://erlang.cc/entity/list');
      setData(response.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'key') setKey(value);
    if (name === 'text') setText(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://erlang.cc/entity', {
        key: key,
        text: text
      });
      console.log('Form submitted successfully:', response.data);
      setText("")
      setKey("")
    } catch (error) {
      console.error('Error submitting form:', error.message);
    }
    await fetchData()
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="Home">
      <h1>Erlang</h1>
      <ul>
        {data?.entities?.map((item) => (
          <li key={item._id}>{item.text}</li>
        ))}
      </ul>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="key">Key:</label>
          <input
            type="text"
            id="key"
            name="key"
            value={key}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="text">Text:</label>
          <input
            type="text"
            id="text"
            name="text"
            value={text}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default Home;
