import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Login from './Components/Login';
import Home from './Components/Home';


function App() {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  // useEffect(() => {
  //   const token = localStorage.getItem('authToken');
  //   if (token){
  //     setIsAuthenticated(true);
  //   }
  // }, [])

  // const authenticate = (token) => {
  //   localStorage.setItem('authToken', token);
  //   setIsAuthenticated(true);
  // };


  return (
    <Home/>
  );
  // return (
  //   <Router>
  //     <Route path="/login" element={<Login authenticate={authenticate}/>} />
  //     <Route path="/" element={isAuthenticated ? <Home/> : <Navigate to ="/login"/>}/> 
  //   </Router>
  // );
}

export default App;
